import { Injectable } from "@angular/core";

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { APIService } from "../application/api.service";

import { ILoginResult } from "../../models/ILoginresult";

import { IChangePassword } from "../../models/IChangePassword";

import { IAppContext, IEnvelope } from "../../framework/interfaces";

import * as FW from "../../framework/core";
import { BaseService } from 'src/app/framework/base-service';

import { IQueryParams } from 'src/app/models/IQueryParams';
import { ContextService } from '../application/context.service';
import { IScope } from 'src/app/models/IScope';
import { IForgotPassword } from 'src/app/models/IForgotPassword';
import { IAppRole } from "../../models/iapprole";
import { IUserAccessToken } from 'src/app/models/IUserAccessToken';

import { UserTwoFactor } from 'src/app/pages/security/login/models/user-twofactor.model';

@Injectable({
  providedIn: 'root',
})
export class SecurityService extends BaseService {

  public get serviceName(): string { return "Security" };
  public appRoles: any;
  private baseUrl: string;

  constructor(public context: ContextService, private api: APIService) {
    super();
    this.baseUrl = "/security";
    // if (!FW.isNullOrBlank(this.context.authService.authToken))
    //   this.searchAppRoles();
  }

  //#region Authentication

  async searchAppRoles(): Promise<IAppRole[]> {
    const params: IQueryParams[] = [
      { type: 'url', value: '', name: '' }
    ];

    this.appRoles = [];
    //let roles: IAppRole[] = [];

    const url = super.fabricateURL(`${this.baseUrl}/role/search`, params);
    const ctx: IAppContext = this.context.join(this.serviceName);

    await this.api.get(ctx, url)
      .toPromise()
      .then(result => {
        if (result != null)
          this.appRoles = result;
      });
    return this.appRoles;
  }

  public getAppRoles() {
    return this.appRoles;
  }




  public impersonate(scope: string, token): Observable<ILoginResult>{
    const ctx: IAppContext = this.context.join(this.serviceName);

    const envelope = new FW.Envelope({
      scope,
      token
    });

    return this.api.post(ctx, "/security/impersonate", envelope).
    pipe(map(this.api.getEnvelopeContent)).
    pipe(map((result: ILoginResult) => {
      if (!FW.isNull(result) && !FW.isNullOrBlank(result.authToken)) {
        return result;
      } else {
        return null;
      }
    }));
  }


  public authenticate(scope: string, email: string, password: string, captchaValue: string): Observable<ILoginResult> {
    const ctx: IAppContext = this.context.join(this.serviceName);
    const validator: FW.ValidationContext = new FW.ValidationContext(ctx);

    validator.requestField("E-mail", email);
    validator.requestField("Senha", password);

    if (!validator.isValid) { return of(null); }

    const loginEnvelope = new FW.Envelope({
      email: email,
      password: password,
      scope: scope,
      captchaValue: captchaValue
    });

    return this.api.post(ctx, "/security/authenticate", loginEnvelope).
      pipe(map(this.api.getEnvelopeContent)).
      pipe(map((result: ILoginResult) => {
        if (!FW.isNull(result) && !FW.isNullOrBlank(result.authToken)) {
          return result;
        } else {
          return null;
        }
      }));
  }

  public changeNewPassword(password: string, newPassword: string, newPasswordConfirm: string): Observable<IChangePassword> {
    const ctx: IAppContext = this.context.join(this.serviceName);
    const validator: FW.ValidationContext = new FW.ValidationContext(ctx);

    validator.requestField("Senha atual", password);
    validator.requestField("Nova Senha", newPassword);
    validator.requestField("Senha", newPasswordConfirm);

    if (!validator.isValid) { return of(null); }

    const changePasswordEnvelope = new FW.Envelope({
      currentPassword: password,
      newPassword: newPassword,
      newPasswordConfirm: newPasswordConfirm
    });

    return this.api.put(ctx, "/security/user/password/change", changePasswordEnvelope).
      pipe(map(this.api.getEnvelopeContent)).
      pipe(map((result: IChangePassword) => {
        if (!FW.isNull(result) && !FW.isNullOrBlank(result.id)) {
          return result;
        } else {
          return null;
        }
      }));
  }

  public getScopeByKey(key: string): Observable<IScope> {
    const url: string = "/security/scope/" + key + "/get";
    const ctx: IAppContext = this.context.join(this.serviceName);
    return this.api.get(ctx, url);
  }

  public recoveryPassword(entity: IForgotPassword): Observable<IEnvelope<IForgotPassword>> {
    const ctx: IAppContext = this.context.join(this.serviceName);
    const validator: FW.ValidationContext = new FW.ValidationContext(ctx);

    validator.requestField("E-mail", entity.email);

    if (!validator.isValid) { return of(null); }

    const postEnvelope = new FW.Envelope(entity);

    return this.api.post(ctx, '/security/recoverpassword', postEnvelope);
  }

  public generateToken(documentNumber: string, type: number): Observable<IEnvelope<IUserAccessToken>> {
    const url: string = "/security/token/generate";
    const ctx: IAppContext = this.context.join(this.serviceName);

    const postEnvelope = new FW.Envelope({
      documentNumber: documentNumber,
      origin: 'produto_assembleia',
      token: '',
      userType: type
    });

    return this.api.post<IUserAccessToken>(ctx, url, postEnvelope);

    //return this.httpClient.post<IEnvelope<T>>(environment.baseApiUrl + url, postEnvelope, httpOptions);
    //return this.api.post(ctx, url, postEnvelope);
  }
  public createTwoFactorToken(entity: UserTwoFactor): Observable<IEnvelope<UserTwoFactor>> {
    const ctx: IAppContext = this.context.join(this.serviceName);
    const postEnvelope = new FW.Envelope(entity);
    return this.api.post(ctx, '/security/createTwoFactorToken', postEnvelope);
  }

  public validateTwoFactorToken(entity: UserTwoFactor): Observable<any> {
    const ctx: IAppContext = this.context.join(this.serviceName);
    const postEnvelope = new FW.Envelope(entity);
    return this.api.post(ctx, '/security/validateTwoFactorToken', postEnvelope);
  }

  authInOmnichannel(docNumber: string, userType: number): Observable<any> {
    const ctx: IAppContext = this.context.join(this.serviceName);
    const postEnvelope = new FW.Envelope({
      doc: docNumber,
      userType: userType,
    });
    return this.api.post(ctx, '/security/authenticate-omni', postEnvelope);
  }

  public getScopeByAlternativeDomain(domain: string): Observable<IScope> {
    const url: string = "/security/scope/alternativeDomain?domain=" + domain;
    const ctx: IAppContext = this.context.join(this.serviceName);
    return this.api.get(ctx, url);
  }
}
