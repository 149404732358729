import { Injectable } from '@angular/core';
import { IMessage } from 'src/app/models/IMessage';

declare var Swal: any;

@Injectable({
  providedIn: 'root'
})
export class MessageSwalService {

  constructor() { }

  displayMessage(msg: IMessage, callback?: any): void {
    switch (msg.warninglevel) {
      case 1:
        if (callback) {
          Swal.fire('Sucesso', msg.message, 'success').then(callback);
        } else {
          Swal.fire('Sucesso', msg.message, 'success');
        }
        break;
      case 2:
        if (callback) {
          Swal.fire('Alerta', msg.message, 'warning').then(callback);
        } else {
          Swal.fire('Alerta', msg.message, 'warning');
        }
        break;
      case 3:
        if (callback) {
          Swal.fire('Erro', msg.message, 'error').then(callback);
        } else {
          Swal.fire('Erro', msg.message, 'error');
        }
        break;
      case 4:
        Swal.fire('Informação', msg.message, 'info');
        break;
      case 5:
        Swal.fire({
          text: `Tem certeza que deseja sair sem salvar?      ` + msg.message,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Tenho certeza'
        }).then(callback)
        break;
      case 6:
        Swal.fire({
          text: msg.message,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: 'Não',
          confirmButtonText: 'Sim'
        }).then(callback)
        break;
      default:
        Swal.fire('Erro', msg.message, 'error');
        break;
    }
  }
}
