import { Injectable } from '@angular/core';
import { IQueryParams } from 'src/app/models/IQueryParams';
import { BaseService } from 'src/app/framework/base-service';
import { APIService } from '../application/api.service';
import { IAppContext, IEnvelope } from 'src/app/framework/interfaces';
import { ContextService } from '../application/context.service';
import { Observable, of } from 'rxjs';
import { WarningLevel } from '../../framework/enums';
import * as FW from '../../framework/core';
import { IUser } from 'src/app/models/IUser';

@Injectable({
    providedIn: 'root'
})
export class UserService extends BaseService {

    private baseUrl: string;

    public get serviceName(): string { return 'User' };
    constructor(private apiService: APIService, public context: ContextService) {
        super();
        this.baseUrl = '/security/user';
    }

    search(params: IQueryParams[]): Observable<FW.Envelope<IUser[]>> {
        const callURL = super.fabricateURL(`${this.baseUrl}/search`, params);
        const ctx: IAppContext = this.context.join(this.serviceName);
        if (callURL) {
            return this.apiService.get(ctx, callURL)
        } else {
            return of(null)
        }
    }

    public read(segmentationId: string): Observable<IUser> {
        const params: IQueryParams[] = [
            { type: 'url', value: segmentationId, name: '' }
        ]
        const callURL = super.fabricateURL(`${this.baseUrl}/get`, params);
        const ctx: IAppContext = this.context.join(this.serviceName);
        return this.apiService.get(ctx, callURL);
    }

    public exclude(segmentationId: string): Observable<IEnvelope<boolean>> {
        const params: IQueryParams[] = [
            { type: 'url', value: segmentationId, name: '' }
        ]
        const callURL = super.fabricateURL(`${this.baseUrl}/exclude`, params);
        const ctx: IAppContext = this.context.join(this.serviceName);
        return this.apiService.delete(ctx, callURL);
    }

    public save(entity: IUser): Observable<IEnvelope<IUser>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const validator: FW.ValidationContext = new FW.ValidationContext(ctx);

        validator.requestField('Nome', entity.name);
        validator.requestField('Email', entity.email);
        validator.requestField('Telefone', entity.cellphone);

        const postEnvelope = new FW.Envelope(entity);

        if (!validator.isValid) {
            postEnvelope.warningLevel = WarningLevel.Warning;
            return of(postEnvelope);
        }

        return this.apiService.post(ctx, `${this.baseUrl}/save`, postEnvelope);
    }

}
