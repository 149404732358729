import { ChangeDetectorRef, Component } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ContextService } from './services/application/context.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'meeting-admin-app';
  constructor(
    private context: ContextService,
    private cdRef: ChangeDetectorRef,
    private oauthService: OAuthService,
    private router: Router) {
    if (environment.production) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }

    if (window.location.hash.includes('redirect')) {
      this.context.authService.scopeKey = window.location.hash.split('/')[2];
    } else {
      this.context.authService.scopeAlternativeDomain = window.location.hostname;
    }

    if (!environment.isHomolog && !environment.production) {
      this.context.authService.scopeKey = "riobravo";
    }

    this.router.navigate(['/']);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart &&
        this.context.authService.currentScope &&
        this.context.authService.currentScope.baseColor) {
        document.body.style.setProperty('--theme-primary', this.context.authService.currentScope.baseColor);
        this.cdRef.detectChanges();
      }
    });
  }
}
